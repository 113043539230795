.contact-form {
    width: 75%;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 160px;
    margin-top: 60px;
    margin-left: 20px;
    margin-right: 6px;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    text-align: center;
}

.row {
    padding: 8px;
}

.col-6 {
    padding: 8px;
}

.col {
    padding: 4px;
}

.submit-btn {
  width: 25%;
  min-width: 64px;
  border: 0;
  border-radius: 4px;
  padding: 8px 16px;
  outline: none;
  background-color: #4f81c7;
  color: #ffffff;
  font-size: 0.875rem;
  font-weight: 1.5;
  letter-spacing: 0.02857rem;
  cursor: pointer;
  transition: all 0.2s;
}

.form-control {
    width: 70%;
    outline: none;
    padding: 8px 16px;
    border: 1px solid #e0e6e8;
    border-radius: 4px;
    font-size: 0.8rem;
    color: #888888;
    transition: box-shadow 0.2s;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .contact-form {
        font-size: 12px;
        width: auto;
        margin-top: 70px;
    }
    .container {
        font-size: 8px;
    }
    .form-control,
    .submit-btn {
        font-size: 10px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .contact-form {
        font-size: 14px;
        width: auto;
        margin-top: 80px;
    }
    .container {
        font-size: 9px;
    }
    .form-control,
    .submit-btn {
        font-size: 14px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .contact-form {
        font-size: 16px;
        width: auto;
        margin-top: 80px;
    }
    .container {
        font-size: 11px;
    }
    .form-control,
    .submit-btn {
        font-size: 16px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .contact-form {
        font-size: 18px;
        padding-left: 8px;
    }
    .container {
        font-size: 13px;
    }
    .form-control,
    .submit-btn {
        font-size: 18px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .contact-form {
        font-size: 20px;
        padding-left: 10px;
    }
    .container {
        font-size: 15px;
    }
    .form-control,
    .submit-btn {
        font-size: 20px;
    }
}